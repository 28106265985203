<template>
  <div id="section">
    <div id="content">
      <GenericTable
        ref="lista"
        title="Cadastros Excluídos"
        name="listaMateriaisDeletados"
        :cols="[
          'Data/Hora',
          'Usuario',
          'ID',
          'Descrição',
          'Tipo de Material',
          'Unidade',
          'Setor',
        ]"
        :cols-name="[
          'Data/Hora',
          'usuario',
          'id',
          'descricao',
          'tipo_material',
          'unidadePatrimonio',
          'setor',
        ]"
        :cols-map="
          (i) => [
            i.dataHora,
            i.usuario,
            i.shownId,
            i.descricao,
            i.tipoMaterial,
            i.unidadePatrimonio,
            i.setor,
          ]
        "
        :route="route"
        :filters="filters"
        :noadd="!noAdd"
        :permissionsToWrite="['rw_material']"
        idKey="id_material"
        hasDeleted
        :hasPagination="true"
        :disabledInputs="disabledInputs"
        @reativa="showModal"
      >
        <template #filters>
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
              <b-form-group>
                <label>Descrição</label>
                <b-form-input
                  v-model="filters.descricao"
                  autocomplete="off"
                  class="invision-input sm"
                  ref="filter_descricao"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
              <b-form-group>
                <label>QR Code</label>
                <b-form-input
                  v-model="filters.code"
                  autocomplete="off"
                  class="invision-input sm"
                  ref="filter_code"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
              <b-form-group>
                <label>ID</label>
                <b-form-input
                  v-model="filters.id"
                  autocomplete="off"
                  class="invision-input sm"
                  ref="filter_id_material"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
              <b-form-group>
                <label>Tipo de Material</label>
                <GenericSelect
                  name="tipoMaterial"
                  labelKey="nome"
                  v-model="filters.id_tipo_material"
                  route="tipoMaterial"
                  ref="filter_tipo_material"
                ></GenericSelect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
          </b-row>
        </template>
      </GenericTable>
      <Modal
        id="modalReativaMaterial"
        ref="modalReativaMaterial"
        title="Reativar Material"
        hide-footer
        no-close-on-backdrop
      >
        <ReativaMaterialModal :item="materialSelected" />
      </Modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import GenericSelect from '@/components/Form/GenericSelect';
import Modal from '@/components/Utils/Modal';
import ReativaMaterialModal from './modalReativarMaterial';

export default {
  components: {
    GenericSelect,
    Modal,
    ReativaMaterialModal,
  },

  data() {
    return {
      route: 'material/materiaisDeletados',
      filters: {
        descricao: '',
        code: '',
        id: '',
        id_tipo_material: '',
        date: {
          type: 'rangeDate',
          defaultInitDate: moment().subtract(7, 'days'),
          defaultEndDate: moment(),
        },
      },
      materialSelected: {},
      hasBlocoOrCmeOrOpme: false,
      noAdd: false,
      handlerXButton: true,
      materialUpdated: {},
      disabledInputs: [],
      optionsUnidade: [
        { value: -1, text: 'Rede' },
      ],
    };
  },

  methods: {
    showModal(item) {
      this.exibemodal = true;
      this.materialSelected = { ...item, ...this.material };
      this.$refs.modalReativaMaterial.show();
    },
    closingModal() {
      this.$refs.modalReativaMaterial.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color-50: #209f85;

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transition {
  transition: 0.3s ease-in-out;
}

#report h2 {
  margin-bottom: 30px;
}

#report table {
  background-color: #FFF;
}

#report table thead th {
  color: #7E829F;
  padding-bottom: 50px;
  font-weight: 900;
}

</style>
